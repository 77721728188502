import { domAnimation, LazyMotion } from "framer-motion"
import { ClientContext } from "graphql-hooks"
import { StrictMode } from "react"
import { RouterProvider } from "react-router-dom"
import { ParallaxProvider } from "react-scroll-parallax"
import { client } from "./lib/api"
import { router } from "./router"
import { StoreProvider } from "./store"

export function Root() {
  return (
    <StrictMode>
      <ClientContext.Provider value={client}>
        <StoreProvider>
          <LazyMotion features={domAnimation}>
            <ParallaxProvider>
              <RouterProvider router={router} />
            </ParallaxProvider>
          </LazyMotion>
        </StoreProvider>
      </ClientContext.Provider>
    </StrictMode>
  )
}
