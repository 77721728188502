import ReactDOM from "react-dom/client"
import "./assets/styles/index.css"
import "./bootstrap"
import { config } from "./config"
import { isProdEnv } from "./lib/env"
import reportWebVitals from "./reportWebVitals"
import { Root } from "./Root"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(<Root />)

if (isProdEnv()) {
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register()
} else {
  serviceWorkerRegistration.unregister()
}

if (config.app.reportWebVital) {
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(config.app.webVitalReporter)
}
