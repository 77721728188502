import cs from "clsx"
import { SVGProps } from "react"
import { ReactComponent as TheFkees } from "../../../assets/images/logo-text.svg"
import styles from "./Brand.module.css"

interface Props extends SVGProps<SVGSVGElement> {
  className?: string
}

export function Brand(props: Props) {
  return <TheFkees {...props} className={cs(styles.brand, props.className)} />
}
