import { forgeStorageKey } from "./storage"

const bannerStorageKey = forgeStorageKey("banner")

export function isBannerVisible() {
  return localStorage.getItem(bannerStorageKey) !== "closed"
}

export function setBannerInvisible() {
  localStorage.setItem(bannerStorageKey, "closed")
}
