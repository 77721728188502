import { useLayoutEffect } from "react"
import { Hex } from "./utils"

export interface Palette {
  black: Hex
  grey900: Hex
  grey800: Hex
  grey700: Hex
  grey600: Hex
  grey500: Hex
  grey400: Hex
  grey300: Hex
  grey200: Hex
  grey100: Hex
  grey50: Hex
  red: Hex
  lightBlue: Hex
  blue: Hex
  darkBlue: Hex
}

export let palette: Palette = getPalette()

export function getPalette(): Palette {
  const computedStyle = getComputedStyle(document.documentElement)

  return {
    black: computedStyle.getPropertyValue("--black"),
    grey900: computedStyle.getPropertyValue("--grey-900"),
    grey800: computedStyle.getPropertyValue("--grey-800"),
    grey700: computedStyle.getPropertyValue("--grey-700"),
    grey600: computedStyle.getPropertyValue("--grey-600"),
    grey500: computedStyle.getPropertyValue("--grey-500"),
    grey400: computedStyle.getPropertyValue("--grey-400"),
    grey300: computedStyle.getPropertyValue("--grey-300"),
    grey200: computedStyle.getPropertyValue("--grey-200"),
    grey100: computedStyle.getPropertyValue("--grey-100"),
    grey50: computedStyle.getPropertyValue("--grey-50"),
    red: computedStyle.getPropertyValue("--red"),
    lightBlue: computedStyle.getPropertyValue("--light-blue"),
    blue: computedStyle.getPropertyValue("--blue"),
    darkBlue: computedStyle.getPropertyValue("--dark-blue"),
  }
}

export function usePaletteUpdater(): void {
  useLayoutEffect(() => {
    palette = getPalette()
  }, [])
}
