import { ShoppingBagIcon } from "@heroicons/react/24/outline"
import { Link, useNavigate } from "react-router-dom"
import { useBagLength } from "../../../modules/Bag/lib"
import { Icon } from "../../image/Icon"
import { Brand } from "../../thefkees/Brand"
import styles from "./Navbar.module.css"

export function Navbar() {
  const navigate = useNavigate()
  const bagLength = useBagLength()

  function navigateToHome() {
    navigate("/")
  }

  return (
    <nav>
      <div className={styles.header}>
        <div className={styles.brandContainer}>
          <Brand className={styles.brand} onClick={navigateToHome} />
        </div>
        <div className={styles.categories}>
          <Link to="/categories/t-shirts">T-shirts</Link>
          <Link to="/categories/sweatshirts">Pull & Sweatshirts</Link>
          <Link to="/categories/sweatshirts">Pantalons</Link>
        </div>
        <div className={styles.links}>
          <Link to="/bag" className={styles.bag}>
            <Icon icon={ShoppingBagIcon} />
            {bagLength > 0 && <span className={styles.bagLength}></span>}
          </Link>
        </div>
      </div>
    </nav>
  )
}
