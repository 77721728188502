import { lazy, Suspense } from "react"
import { createBrowserRouter } from "react-router-dom"
import { App } from "./App"
import { PageLoader } from "./components/layout/PageLoader"

const Home = lazy(() => import("./modules/Home"))
const About = lazy(() => import("./modules/About"))
const Bag = lazy(() => import("./modules/Bag"))
const Category = lazy(() => import("./modules/Category"))
const Contact = lazy(() => import("./modules/Contact"))
const ExchangesAndReturns = lazy(() => import("./modules/ExchangesAndReturns"))
const Order = lazy(() => import("./modules/Order"))
const Privacy = lazy(() => import("./modules/Privacy"))
const Product = lazy(() => import("./modules/Product"))
const Terms = lazy(() => import("./modules/Terms"))
const TrackMyOrder = lazy(() => import("./modules/TrackMyOrder"))

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<PageLoader />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "/categories",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "/categories/:category",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Category />
          </Suspense>
        ),
      },
      {
        path: "/categories/:category/:product",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Product />
          </Suspense>
        ),
      },
      {
        path: "/bag",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Bag />
          </Suspense>
        ),
      },
      {
        path: "/order",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Order />
          </Suspense>
        ),
      },
      {
        path: "/order/:orderId",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Order />
          </Suspense>
        ),
      },
      {
        path: "/track-my-order",
        element: (
          <Suspense fallback={<PageLoader />}>
            <TrackMyOrder />
          </Suspense>
        ),
      },
      {
        path: "/track-my-order/:orderId",
        element: (
          <Suspense fallback={<PageLoader />}>
            <TrackMyOrder />
          </Suspense>
        ),
      },
      {
        path: "/terms-and-conditions",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Terms />
          </Suspense>
        ),
      },
      {
        path: "/privacy",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Privacy />
          </Suspense>
        ),
      },
      {
        path: "/contact",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Contact />
          </Suspense>
        ),
      },
      {
        path: "/about",
        element: (
          <Suspense fallback={<PageLoader />}>
            <About />
          </Suspense>
        ),
      },
      {
        path: "/exchanges-and-returns",
        element: (
          <Suspense fallback={<PageLoader />}>
            <ExchangesAndReturns />
          </Suspense>
        ),
      },
    ],
  },
])
