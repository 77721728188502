import { Category } from "../../Category/lib"
import { Color, Product, ProductSize } from "../../Product/lib"

export const productsByIdsQuery = `
  query ProductsByIdsQuery($ids: [ID!]!) {
    productsByIds(ids: $ids) {
      id
      name
      category {
        slug
      }
      cover
      slug
      price
      color {
        name
        hex
      }
      sizes {
        size
        stock
        available
      }
    }
  }
`

export type ProductsByIdsQueryProduct = Pick<
  Product,
  "id" | "cover" | "name" | "slug" | "price"
> & {
  color: Pick<Color, "name" | "hex">
  sizes: ProductSize[]
  category: Pick<Category, "slug">
}

export interface ProductsByIdsQueryResponse {
  productsByIds: ProductsByIdsQueryProduct[]
}
