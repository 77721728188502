import { Currency } from "./lib/globals"

const apiBaseURL = process.env.REACT_APP_API_BASE_URL ?? "http://localhost:4000"

export const config = {
  app: {
    reportWebVital: process.env.REACT_APP_REPORT_WEB_VITALS === "true",
    webVitalReporter: console.log,
  },

  api: {
    baseURL: apiBaseURL,
    graphqlURL: `${apiBaseURL}/graphql`,
  },

  order: {
    minimumAmount: Object.values(Currency).reduce(
      (acc, currency) => ({ ...acc, [currency]: 1 }),
      {}
    ) as { [C in Currency]: number },
  },

  stripe: {
    publishable: process.env.REACT_APP_STRIPE_PUBLISHABLE as string,
  },

  ga: {
    tagId: process.env.REACT_APP_GA_TAG_ID,
  },
}
