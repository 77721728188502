import { Currency, Locale } from "../lib/globals"
import { ID, uid } from "../lib/utils"
import { BagItem } from "../modules/Bag/lib"
import { Category } from "../modules/Category/lib"
import { Product } from "../modules/Product/lib"
import {
  AddToBagAction,
  ADD_TO_BAG,
  LoadStoreAction,
  LOAD_STORE,
  RemoveFromBagAction,
  REMOVE_FROM_BAG,
  SaveProductsAction,
  SAVE_PRODUCTS,
  SetBagAction,
  SetBagItemQuantityAction,
  SetCategoriesAction,
  SetCurrencyAction,
  SetLocaleAction,
  SetOrderIdAction,
  SET_BAG,
  SET_BAG_ITEM_QUANTITY,
  SET_CATEGORIES,
  SET_CURRENCY,
  SET_LOCALE,
  SET_ORDER_ID,
  StoreAction,
} from "./actions"

export interface StoreState {
  loaded: boolean
  currency: Currency
  locale: Locale
  categories: Category[]
  products: Product[]
  bag: BagItem[]
  orderId: ID | null
}

export type PersistedStoreState = Pick<
  StoreState,
  "currency" | "locale" | "bag"
>

export const persistedStoreKeys = ["currency", "locale", "bag", "orderId"]

export const initialState: StoreState = {
  loaded: false,
  currency: Currency.eur,
  locale: Locale.fr,
  categories: [],
  products: [],
  bag: [],
  orderId: null,
}

export default function reducer(
  state: StoreState,
  action: StoreAction
): StoreState {
  switch (action.type) {
    case LOAD_STORE: {
      const { state: loadedState } = action as LoadStoreAction

      return {
        ...initialState,
        ...loadedState,
        loaded: true,
      }
    }

    case SET_CURRENCY: {
      const { currency } = action as SetCurrencyAction

      return {
        ...state,
        currency,
      }
    }

    case SET_LOCALE: {
      const { locale } = action as SetLocaleAction

      return {
        ...state,
        locale,
      }
    }

    case SET_CATEGORIES: {
      const { categories } = action as SetCategoriesAction

      return {
        ...state,
        categories,
      }
    }

    case SAVE_PRODUCTS: {
      const { products } = action as SaveProductsAction
      const productIds = products.map((product) => product.id)

      return {
        ...state,
        products: [
          ...state.products.filter(
            (product) => !productIds.includes(product.id)
          ),
          ...products,
        ],
      }
    }

    case SET_BAG: {
      const { items } = action as SetBagAction

      return {
        ...state,
        bag: items,
      }
    }

    case ADD_TO_BAG: {
      const { product, size, quantity } = action as AddToBagAction

      if (
        state.bag.some(
          (item) => item.product.id === product.id && item.size === size
        )
      ) {
        return {
          ...state,
          bag: state.bag.map((item) =>
            item.product.id === product.id
              ? { ...item, quantity: item.quantity + quantity }
              : item
          ),
        }
      }

      return {
        ...state,
        bag: [...state.bag, { bid: uid(), product, size, quantity }],
      }
    }

    case SET_BAG_ITEM_QUANTITY: {
      const { item, quantity } = action as SetBagItemQuantityAction

      return {
        ...state,
        bag: state.bag.map((currentItem) =>
          currentItem.bid === item.bid
            ? { ...currentItem, quantity }
            : currentItem
        ),
      }
    }

    case REMOVE_FROM_BAG: {
      const { item } = action as RemoveFromBagAction

      return {
        ...state,
        bag: state.bag.filter((currentItem) => currentItem.bid !== item.bid),
      }
    }

    case SET_ORDER_ID: {
      const { id } = action as SetOrderIdAction

      return {
        ...state,
        orderId: id,
      }
    }

    default:
      return state
  }
}
