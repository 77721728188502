import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { ReactNode } from "react"
import { Text } from "../../typography/Text"
import styles from "./Banner.module.css"

interface Props {
  children?: ReactNode | ReactNode[]
  onClose?(): void
}

export function Banner({ children, onClose }: Props) {
  return (
    <div className={styles.root}>
      <MegaphoneIcon className={styles.megaphone} />
      <Text className={styles.content}>{children}</Text>
      <XMarkIcon className={styles.close} onClick={onClose} />
    </div>
  )
}
