import { APIError, useManualQuery } from "graphql-hooks"
import isEqual from "lodash/isEqual"
import { useEffect } from "react"
import { DeliveryOption } from "../../../lib/delivery"
import { ID } from "../../../lib/utils"
import { useStore } from "../../../store"
import { setBag } from "../../../store/actions"
import { Size } from "../../Product/lib"
import {
  productsByIdsQuery,
  ProductsByIdsQueryProduct,
  ProductsByIdsQueryResponse,
} from "../graphql/productsByIds.graphql"

export interface BagItem {
  bid: ID
  product: BagProduct
  size: Size
  quantity: number
}

export type BagProduct = ProductsByIdsQueryProduct

export function useBagItems(): BagItem[] {
  // @todo: update each bag product from API
  return useStore().state.bag
}

export function useBagTotalPrice(
  deliveryOptions?: DeliveryOption | null
): number {
  const items = useBagItems()

  return (
    items.reduce(
      (total, item) => total + item.product.price * item.quantity,
      0
    ) + (deliveryOptions?.price ?? 0)
  )
}

export function useBagLength(): number {
  return useBagItems().length
}

export function useBagItemsUpdater(): [boolean, APIError | undefined] {
  const items = useBagItems()
  const ids = items.map((item) => item.product.id)
  const { dispatch } = useStore()
  const [fetchUpdatedItems, { loading, error }] =
    useManualQuery<ProductsByIdsQueryResponse>(productsByIdsQuery, {
      variables: { ids },
    })

  useEffect(() => {
    async function updateBagItems() {
      const response = await fetchUpdatedItems()
      const { data } = response

      if (data && Array.isArray(data?.productsByIds)) {
        const updatedItems = items.map((item) => {
          const updatedProduct = data.productsByIds.find(
            (product) => product.id === item.product.id
          )

          if (!updatedProduct) {
            return item
          }

          return {
            ...item,
            product: updatedProduct,
          }
        })

        if (!isEqual(items, updatedItems)) {
          dispatch(setBag(updatedItems))
        }
      }
    }

    updateBagItems()
  }, [items, dispatch, fetchUpdatedItems])

  return [loading, error]
}
