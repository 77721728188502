import { ReactComponent as InstagramIcon } from "../../../../../assets/icons/instagram.svg"
import { ReactComponent as TwitterIcon } from "../../../../../assets/icons/twitter.svg"
import { openExternalLink } from "../../../../../lib/navigation"
import styles from "./Socials.module.css"

export function Socials() {
  return (
    <div className={styles.root}>
      <InstagramIcon
        className={styles.icon}
        onClick={() => openExternalLink("https://www.instagram.com/the_fkees/")}
      />
      <TwitterIcon
        className={styles.icon}
        onClick={() => openExternalLink("https://twitter.com/the_fkees")}
      />
    </div>
  )
}
