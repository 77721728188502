import { Outlet } from "react-router-dom"
import { useResetScrollOnNavigation } from "./lib/navigation"
import { usePaletteUpdater } from "./lib/palette"
import { useStoreLoader, useStorePersistor } from "./lib/store"
import { useThemeUpdater } from "./lib/theme"

export function App() {
  useStoreLoader()
  useStorePersistor()
  useThemeUpdater()
  usePaletteUpdater()
  useResetScrollOnNavigation()

  return <Outlet />
}
