import { m as motion } from "framer-motion"
import { ReactNode, useState } from "react"
import { useLocation } from "react-router-dom"
import { isBannerVisible, setBannerInvisible } from "../../../lib/banner"
import { Banner } from "../../alert/Banner"
import { Footer } from "./Footer"
import { Header } from "./Header"
import styles from "./Page.module.css"

interface Props {
  className?: string
  children?: ReactNode | ReactNode[]
}

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.2,
}

export function Page({ className, children }: Props) {
  const { pathname } = useLocation()
  const [showBanner, setShowBanner] = useState(isBannerVisible())

  function closeBanner() {
    setBannerInvisible()
    setShowBanner(false)
  }

  return (
    <main className={className}>
      {showBanner && (
        <Banner onClose={closeBanner}>
          La livraison standard est offerte pour toute commande sur le site.
        </Banner>
      )}
      <Header />
      <motion.section
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
        className={styles.content}
      >
        {children}
      </motion.section>
      <Footer />
    </main>
  )
}
