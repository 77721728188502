import { Link } from "react-router-dom"
import styles from "./Links.module.css"

export function Links() {
  return (
    <div className={styles.root}>
      <Link to="/terms-and-conditions">
        Conditions G&eacute;n&eacute;rale de Vente
      </Link>
      <Link to="/privacy">Politique de confidentialit&eacute;</Link>
      <Link to="/exchanges-and-returns">&Eacute;changes &amp; Retours</Link>
      <Link to="/track-my-order">Suivre ma commande</Link>
      <Link to="/contact">Nous contacter</Link>
      <Link to="/about">À propos</Link>
    </div>
  )
}
