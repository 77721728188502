import { useLayoutEffect } from "react"
import { Hex } from "./utils"

export interface Theme {
  textColor: Hex
  textColorInverted: Hex
  secondaryTextColor: Hex
  titleColor: Hex
  textPrimaryColor: Hex
  textActivePrimaryColor: Hex
  shadowColor: Hex
  primaryColor: Hex
  activePrimaryColor: Hex
  borderColor: Hex
  inputBackgroundColor: Hex
  dangerColor: Hex
  fadedColor: Hex
  infoColor: Hex
  fadedInfoColor: Hex
  successColor: Hex
  fadedSuccessColor: Hex
  warningColor: Hex
  fadedWarningColor: Hex
}

export let theme: Theme = getTheme()

export function getTheme(): Theme {
  const computedStyle = getComputedStyle(document.documentElement)

  return {
    /* Text colors */
    textColor: computedStyle.getPropertyValue("--text-color"),
    textColorInverted: computedStyle.getPropertyValue("--text-color-inverted"),
    secondaryTextColor: computedStyle.getPropertyValue(
      "--secondary-text-color"
    ),
    titleColor: computedStyle.getPropertyValue("--title-color"),
    textPrimaryColor: computedStyle.getPropertyValue("--text-primary-color"),
    textActivePrimaryColor: computedStyle.getPropertyValue(
      "--text-active-primary-color"
    ),

    /* Colors */
    shadowColor: computedStyle.getPropertyValue("--shadow-color"),
    primaryColor: computedStyle.getPropertyValue("--primary-color"),
    activePrimaryColor: computedStyle.getPropertyValue(
      "--active-primary-color"
    ),
    borderColor: computedStyle.getPropertyValue("--border-color"),
    inputBackgroundColor: computedStyle.getPropertyValue(
      "--input-background-color"
    ),
    dangerColor: computedStyle.getPropertyValue("--danger-color"),
    fadedColor: computedStyle.getPropertyValue("--faded-danger-color"),
    infoColor: computedStyle.getPropertyValue("--info-color"),
    fadedInfoColor: computedStyle.getPropertyValue("--faded-info-color"),
    successColor: computedStyle.getPropertyValue("--success-color"),
    fadedSuccessColor: computedStyle.getPropertyValue("--faded-success-color"),
    warningColor: computedStyle.getPropertyValue("--warning-color"),
    fadedWarningColor: computedStyle.getPropertyValue("--faded-warning-color"),
  }
}

export function useThemeUpdater(): void {
  useLayoutEffect(() => {
    theme = getTheme()
  }, [])
}
