import { Navbar } from "../../../navigation/Navbar"
import { Content } from "../../Content"
import styles from "./Header.module.css"

export function Header() {
  return (
    <header className={styles.root}>
      <Content>
        <Navbar />
        <div className={styles.separator} />
      </Content>
    </header>
  )
}
