import { Content } from "../../Content"
import styles from "./Footer.module.css"
import { Links } from "./Links"
import { Socials } from "./Socials"

export function Footer() {
  return (
    <footer className={styles.root}>
      <Content className={styles.content}>
        <div className={styles.separator} />
        <Links />
        <Socials />
        <span className={styles.copyright}>
          &copy; {new Date().getFullYear()} THE FKEES. All rights reserved.
        </span>
      </Content>
    </footer>
  )
}
