/**
 * @note this file is here to avoid circulard dependencies
 * i.e. store/reducer.ts => lib/currency.ts => store/index.ts => store/reducer.ts
 */
export enum Currency {
  eur = "eur",
  gbp = "gbp",
  usd = "usd",
}

export enum Locale {
  af = "af",
  sq = "sq",
  am = "am",
  ar = "ar",
  hy = "hy",
  as = "as",
  az = "az",
  eu = "eu",
  be = "be",
  bn = "bn",
  bs = "bs",
  bg = "bg",
  my = "my",
  ca = "ca",
  zh = "zh",
  hr = "hr",
  cs = "cs",
  da = "da",
  dv = "dv",
  nl = "nl",
  en = "en",
  et = "et",
  mk = "mk",
  fo = "fo",
  fa = "fa",
  fi = "fi",
  fr = "fr",
  d = "d",
  gd = "gd",
  gl = "gl",
  ka = "ka",
  de = "de",
  el = "el",
  gn = "gn",
  gu = "gu",
  he = "he",
  hi = "hi",
  hu = "hu",
  is = "is",
  id = "id",
  it = "it",
  ja = "ja",
  kn = "kn",
  ks = "ks",
  kk = "kk",
  km = "km",
  ko = "ko",
  lo = "lo",
  la = "la",
  lv = "lv",
  lt = "lt",
  ms = "ms",
  ml = "ml",
  mt = "mt",
  mi = "mi",
  mr = "mr",
  mn = "mn",
  ne = "ne",
  nb = "nb",
  nn = "nn",
  or = "or",
  pl = "pl",
  pt = "pt",
  pa = "pa",
  rm = "rm",
  ro = "ro",
  ru = "ru",
  sa = "sa",
  sr = "sr",
  tn = "tn",
  sd = "sd",
  si = "si",
  sk = "sk",
  sl = "sl",
  so = "so",
  sb = "sb",
  es = "es",
  sw = "sw",
  sv = "sv",
  tg = "tg",
  ta = "ta",
  tt = "tt",
  te = "te",
  th = "th",
  bo = "bo",
  ts = "ts",
  tr = "tr",
  tk = "tk",
  uk = "uk",
  ur = "ur",
  uz = "uz",
  vi = "vi",
  cy = "cy",
  xh = "xh",
  yi = "yi",
  zu = "zu",
}
