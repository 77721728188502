import { Currency, Locale } from "../lib/globals"
import { ID } from "../lib/utils"
import { BagItem, BagProduct } from "../modules/Bag/lib"
import { Category } from "../modules/Category/lib"
import { Product, Size } from "../modules/Product/lib"
import { StoreState } from "./reducer"

export type LoadStoreAction = StoreAction & { state: Partial<StoreState> }
export const LOAD_STORE = "LOAD_STORE"
export const loadStore = (state: Partial<StoreState>): LoadStoreAction => ({
  type: LOAD_STORE,
  state,
})

export type SetCurrencyAction = StoreAction & { currency: Currency }
export const SET_CURRENCY = "SET_CURRENCY"
export const setCurrency = (currency: Currency): SetCurrencyAction => ({
  type: SET_CURRENCY,
  currency,
})

export type SetLocaleAction = StoreAction & { locale: Locale }
export const SET_LOCALE = "SET_LOCALE"
export const setLocale = (locale: Locale): SetLocaleAction => ({
  type: SET_LOCALE,
  locale,
})

export type SetCategoriesAction = StoreAction & { categories: Category[] }
export const SET_CATEGORIES = "SET_CATEGORIES"
export const setCategories = (categories: Category[]): SetCategoriesAction => ({
  type: SET_CATEGORIES,
  categories,
})

export type SaveProductsAction = StoreAction & { products: Product[] }
export const SAVE_PRODUCTS = "SAVE_PRODUCTS"
export const saveProducts = (products: Product[]): SaveProductsAction => ({
  type: SAVE_PRODUCTS,
  products,
})

export type SetBagAction = StoreAction & {
  items: BagItem[]
}
export const SET_BAG = "SET_BAG"
export const setBag = (items: BagItem[]): SetBagAction => ({
  type: SET_BAG,
  items,
})

export type AddToBagAction = StoreAction & {
  product: BagProduct
  size: Size
  quantity: number
}
export const ADD_TO_BAG = "ADD_TO_BAG"
export const addToBag = (
  product: BagProduct,
  size: Size,
  quantity: number
): AddToBagAction => ({
  type: ADD_TO_BAG,
  product,
  size,
  quantity,
})

export type SetBagItemQuantityAction = StoreAction & {
  item: BagItem
  quantity: number
}
export const SET_BAG_ITEM_QUANTITY = "SET_BAG_ITEM_QUANTITY"
export const setBagItemQuantity = (
  item: BagItem,
  quantity: number
): SetBagItemQuantityAction => ({
  type: SET_BAG_ITEM_QUANTITY,
  item,
  quantity,
})

export type RemoveFromBagAction = StoreAction & { item: BagItem }
export const REMOVE_FROM_BAG = "REMOVE_FROM_BAG"
export const removeFromBag = (item: BagItem): RemoveFromBagAction => ({
  type: REMOVE_FROM_BAG,
  item,
})

export type SetOrderIdAction = StoreAction & { id: ID | null }
export const SET_ORDER_ID = "SET_ORDER_ID"
export const setOrderId = (id: ID | null): SetOrderIdAction => ({
  type: SET_ORDER_ID,
  id,
})

export type StoreActionType =
  | "LOAD_STORE"
  | "SET_CURRENCY"
  | "SET_LOCALE"
  | "SET_CATEGORIES"
  | "SAVE_PRODUCTS"
  | "SET_BAG"
  | "ADD_TO_BAG"
  | "SET_BAG_ITEM_QUANTITY"
  | "REMOVE_FROM_BAG"
  | "SET_ORDER_ID"

export interface StoreAction {
  type: StoreActionType
  [key: string]: unknown
}
