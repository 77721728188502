import pick from "lodash/pick"
import { useEffect } from "react"
import { useStore } from "../store"
import { loadStore } from "../store/actions"
import {
  initialState,
  persistedStoreKeys,
  PersistedStoreState,
  StoreState,
} from "../store/reducer"
import { forgeStorageKey } from "./storage"

const storeStorageKey = forgeStorageKey("state")

export function persistStore(state: StoreState) {
  localStorage.setItem(
    storeStorageKey,
    JSON.stringify(pick(state, persistedStoreKeys))
  )
}

export function retrieveStore(): PersistedStoreState {
  const retrievedState = localStorage.getItem(storeStorageKey)

  try {
    return JSON.parse(retrievedState ?? JSON.stringify(initialState))
  } catch {
    return initialState
  }
}

export function useStoreLoader() {
  const { state, dispatch } = useStore()

  useEffect(() => {
    if (!state.loaded) {
      dispatch(loadStore(retrieveStore()))
    }
  }, [state.loaded, dispatch])
}

export function useStorePersistor() {
  const { state } = useStore()

  useEffect(() => {
    if (state.loaded) {
      persistStore(state)
    }
  }, [state])
}
