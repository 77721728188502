import { ReactNode, useEffect } from "react"
import { useLocation } from "react-router-dom"

export function openExternalLink(url: string) {
  const win = window.open(url, "_blank")

  if (win) {
    win.focus()
  }
}

export type Step = ReactNode | ReactNode[]

export function useResetScrollOnNavigation() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
}
