import { v4 as uuidv4 } from "uuid"

export type Maybe<T> = void | null | T

export type Nullable<T> = T | null

export type ID = string

export type Hex = string

export type Dictionary<
  T extends string | number | symbol = string,
  U = unknown
> = Record<T, U>

export function uid() {
  return uuidv4()
}

export function isValidEmail(email: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}
